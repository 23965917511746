import React from 'react';
import { Container, Grid, Typography, Link, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/images/logo.png';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: '#1b1b1b',
    color: '#ffffff',
    backgroundImage: 'linear-gradient(150deg, #1ad4778 0%, #3e174b6 100%)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: theme.spacing(4, 0),
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
    fontSize: '1.5rem',
    alignContent: 'center',
  },
  socialIcon1: {
    width: '30px',
    height: '30px',
    color: '#ffffff',
    marginRight: theme.spacing(2),
    fontSize: '1.5rem',
  },
  divider: {
    backgroundColor: '#ffffff',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: 'inherit',
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  const navigationMenu = [
    { label: 'Home', href: '/' },
    { label: 'About Us', href: '/about-us' },
    { label: 'Contact Us', href: '/contact-us' },
  ];

  const quickLinksMenu = [
    { label: 'FAQs', href: '/faqs' },
    { label: 'Terms & Conditions', href: '/terms-conditions' },
    { label: 'Privacy Policy', href: '/privacy-policy' },
    { label: 'Cookie Policy', href: '/cookie-policy' },
    { label: 'ModernSlavery Statement', href: '/modern-slavery-policy' },
  ];

  const servicesMenu = [
    { label: 'Rider', href: '/riders' },
    { label: 'Driver', href: '/drivers' },
    { label: 'Business', href: '/business' },
  ];

  return (
    <footer className={classes.footer}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3} className={classes.section}>
            <img
              src={logo}
              alt="Logo"
              style={{ width: '100%', maxWidth: '150px' }}
            />
            <Typography variant="body2" sx={{ mt: 2 }}>
              Licensed Taxi Limited 
              <br/>
              Company Number- 12923628
              <br/>
              New Forest Enterprise Centre
              <br />
              Unit 36b, Chapel Lane
              <br />
              Totton, Southampton
              <br />
              SO40 9LA
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} className={classes.section}>
            <Typography variant="h6" gutterBottom>
              Navigation
            </Typography>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              {navigationMenu.map((item) => (
                <li key={item.label}>
                  <Link href={`${item.href}`} color="inherit" underline="none">
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12} md={3} className={classes.section}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              {quickLinksMenu.map((item) => (
                <li key={item.label}>
                  <Link href={`${item.href}`} color="inherit" underline="none">
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12} md={3} className={classes.section}>
            <Typography variant="h6" gutterBottom>
              Services
            </Typography>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              {servicesMenu.map((item) => (
                <li key={item.label}>
                  <Link href={`${item.href}`} color="inherit" underline="none">
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>
        <Grid container spacing={4} sx={{ mt: 4, ml: 0 }} justifyContent="space-between" >
          {/* <Grid item xs={12} md={4} className={classes.section}> */}
          {/* <Box display="flex" alignItems="center"> */}
          <a href="mailto:Contact@licensedtaxi.co.uk" className={classes.link}>
            <FontAwesomeIcon icon={faEnvelopeOpenText} className={classes.icon} />
            <Typography variant="body2">Contact@licensedtaxi.co.uk</Typography>
          </a>
          {/* </Box> */}
          {/* </Grid> */}
          {/* <Grid item xs={12} md={4} className={classes.section}>
            <Box display="flex" justifyContent={{ xs: 'center', md: 'flex-end' }}>
              <Link href="#" className={classes.socialIcon1} style={{ color: 'white', fontSize: '1.5rem', marginRight: '10px' }}>
                <FontAwesomeIcon icon={faFacebook} />
              </Link>
              <Link href="#" className={classes.socialIcon1} style={{ color: 'white', fontSize: '1.5rem', marginRight: '10px' }}>
                <FontAwesomeIcon icon={faTwitter} />
              </Link>
              <Link href="#" className={classes.socialIcon1} style={{ color: 'white', fontSize: '1.5rem', marginRight: '10px' }}>
                <FontAwesomeIcon icon={faYoutube} />
              </Link>
            </Box>
          </Grid> */}
        </Grid>
        <Divider className={classes.divider} sx={{ my: 4 }} />
        <Typography variant="body2" align="center">
          © 2024 Licensed-Taxi • All Rights Reserved
        </Typography>
      </Container>
    </footer>
  );
};

export default Footer;
